<template>
  <section>
    <ClientOnly>
      <VRuntimeTemplate
        :template="`<div class='cms-content'>${this.html}</div>`"
      ></VRuntimeTemplate>
    </ClientOnly>
  </section>
</template>

<script>
import ClientOnly from "vue-client-only";
import VRuntimeTemplate from "v-runtime-template";
import { isServer } from "@storefront/core/helpers";

export default {
  props: ["template"],

  data: () => ({
    html: null,
    scriptStr: null,
  }),
  components: {
    ClientOnly,
    VRuntimeTemplate,
  },

  created() {
    if (!isServer) {
      this.html = this.$props.template.split("<script")[0];
      this.scriptStr = this.$props.template.split("<script")[1];

      this.scriptStr = this.scriptStr.replace("script>", "");
      this.scriptStr = this.scriptStr.replace("></", "");
      this.scriptStr = this.scriptStr.replaceAll('"', "");

      const scriptTagAttributes = this.scriptStr
        .split(" ")
        .filter((el) => el !== "")
        .map((el) => el.split("="));

      const scriptTag = document.createElement("script");

      scriptTagAttributes.forEach((el) => {
        scriptTag.setAttribute(el[0], el[1] ? el[1] : "");
      });

      document.getElementsByTagName("head")[0].appendChild(scriptTag);
    }
  },
};
</script>

<style lang="scss" scoped></style>