<template>
  <div>
    <Breadcrumbs />
    <section class="static pt-7">
      <b-container>
        <b-row>
          <b-col lg="12">
            <!-- <h4>{{ cmsPageContent.title }}</h4> -->

            <div v-if="slider != null">
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="5000"
                indicators
                img-width="1920"
                img-height="300"
                style="text-shadow: 1px 1px 2px #333"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
                class="hero-banner-carousel category-sliderMain"
              >
                <b-link
                  :href="bSlide.link"
                  class="btn btn-dark ml-30 ml-md-0"
                  v-for="(bSlide, index) in slider.slides"
                  :key="index"
                  :target="getTarget(bSlide.link)"
                  :rel="getRel(bSlide.link)"
                >
                  <b-carousel-slide
                    :style="{ backgroundImage: `url(${bSlide.media.url})` }"
                    class="category-slider"
                  >
                  </b-carousel-slide>
                </b-link>
              </b-carousel>
            </div>

            <div class="go-back-btn container for-mobile my-3">
              <b-link @click.native="$router.back()"
                ><b-icon icon="chevron-left"></b-icon
                >{{ $t("go_back") }}</b-link
              >
            </div>

            <div v-if="!hasContainScript">
              <VRuntimeTemplate
                v-if="cmsPageContent.content"
                :template="`<div class='cms-content'>${cmsPageContent.content}</div>`"
              ></VRuntimeTemplate>
            </div>

            <div v-else>
              <StaticPageWithScript :template="cmsPageContent.content">
              </StaticPageWithScript>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";

import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import StaticPageWithScript from "@/base/core/components/StaticPageWithScript";

import VRuntimeTemplate from "v-runtime-template";
import { getCurrentLanguage } from "@storefront/core/i18n";

export default {
  name: "StaticPage1Column",
  data: () => ({
    slider: null,
  }),
  components: {
    Breadcrumbs,
    StaticPageWithScript,
    VRuntimeTemplate,
  },
  computed: {
    cmsPageId() {
      return this.$store.getters["cmsPage/getCurrentId"];
    },
    cmsPageContent() {
      return this.$store.state.cmsPage.current;
    },
    hasContainScript() {
      return (
        this.cmsPageContent && this.cmsPageContent.content.includes("<script")
      );
    },
    categorySlider() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "category_slider"
      );
    },
  },
  methods: {
    async getCmsPageSlider() {
      const lang = getCurrentLanguage();
      const storelang = config.languages[lang];
      const storeview = storelang["storeview"];

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + config.shop.accessToken,
        Store: storeview,
      };
      const query =
        '{ getTailoredSlider(pageType: "cms_pages" cmsIdentifier:"' +
        this.cmsPageContent.identifier +
        '") { title fullwidth location slides { title description link buttonText media { url type previewType } } } }' +
        "\n";
      const retval = await axios({
        url: config.shop.graphQLURL + "?query=" + encodeURI(query),
        method: "GET",
        headers: headers,
      }).catch((e) => {
        Logger.error("loadSliderById", "data-resolver sliders", e)();
        throw e;
      });

      if (retval) {
        this.slider = retval.data.data.getTailoredSlider[0];
      }
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    getTarget(link) {
      if (link && link.substring(0, 4) == "http") {
        return "_blank";
      } else {
        return "_self";
      }
    },
    getRel(link) {
      if (link && link.substring(0, 4) == "http") {
        return "noopener noreferrer";
      } else {
        return "";
      }
    },
  },
  watch: {
    cmsPageContent() {
      const bcrumb = { current: this.cmsPageContent.title, routes: [] };
      this.$store.commit("breadcrumbs/set", bcrumb);
    },
  },
  mounted() {
    const bcrumb = { current: this.cmsPageContent.title, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);

    // Load Slider...

    this.getCmsPageSlider();
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content: this.cmsPageContent
            ? this.cmsPageContent.meta_title &&
              this.cmsPageContent.meta_title.length > 0
              ? this.cmsPageContent.meta_title
              : this.cmsPageContent.title ?? ""
            : "",
        },
        { name: "keywords", content: this.cmsPageContent.meta_keywords ?? "" },
        {
          name: "description",
          content: this.cmsPageContent.meta_description ?? "",
        },
        {
          property: "og:title",
          content: this.cmsPageContent
            ? this.cmsPageContent.meta_title &&
              this.cmsPageContent.meta_title.length > 0
              ? this.cmsPageContent.meta_title
              : this.cmsPageContent.title ?? ""
            : "",
        },
        {
          property: "og:description",
          content: this.cmsPageContent.meta_description ?? "",
        },
        {
          name: "twitter:title",
          content: this.cmsPageContent
            ? this.cmsPageContent.meta_title &&
              this.cmsPageContent.meta_title.length > 0
              ? this.cmsPageContent.meta_title
              : this.cmsPageContent.title ?? ""
            : "",
        },
        {
          name: "twitter:description",
          content: this.cmsPageContent.meta_description ?? "",
        },
      ],
      title: this.cmsPageContent
        ? this.cmsPageContent.meta_title &&
          this.cmsPageContent.meta_title.length > 0
          ? this.cmsPageContent.meta_title
          : this.cmsPageContent.title ?? ""
        : "",
    };
  },
};
</script>
<style lang="scss">
.go-back-btn {
  .for-mobile {
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.title {
  display: none;
}

.col-sm-6 {
  float: left;
  font-size: 14px;
}

.blog_tekst {
  font-size: 14px;
}
.toolbar.toolbar-products {
  display: none;
}
.page-layout-1column.page-with-filter .column.main {
  padding-top: 0px !important;
}
</style>

